<template>
  <div class="admin-stats-coverage">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.repeat" v-b-tooltip title="Re/Run Stats" @click="clickAddTask"></i>
<!--        <i class="icon-button" :class="$config.icons.general.failure" v-b-tooltip title="Delete Stats" @click="clickRerun"></i>-->
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh Coverage" @click="refreshList"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Stats Coverage Tool</h2></b-card-header>
        <b-card-body>
          <AdminStatsCalendar ref="calendar"></AdminStatsCalendar>
        </b-card-body>
      </b-card>
    </div>
    <b-modal title="Create Stats Task" id="create-task-modal" hide-footer size="lg">
      <AdminStatsCreateTask modal_id="create-task-modal"></AdminStatsCreateTask>
    </b-modal>
  </div>
</template>

<script>

import AdminStatsCalendar from '@/components/admin/AdminStatsCalendar.vue'
import AdminStatsCreateTask from '@/components/admin/AdminStatsCreateTask.vue'

export default {
  name: 'admin-stats-coverage',
  components: {
    AdminStatsCreateTask,
    AdminStatsCalendar
  },
  data: function () {
    return {
      selectedUsers: []
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.calendar.refresh()
    },
    clickAddTask () {
      this.$bvModal.show('create-task-modal')
    },
    clickRerun () {

    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-stats-coverage {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
