var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stats-calendar" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "filter-bar" }, [
        _c(
          "form",
          {
            staticClass: "control-form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.getStatsCoverage()
              }
            }
          },
          [
            _c("div", { staticClass: "position-relative z-2 flex-grow-1" }, [
              _c("label", { staticClass: "green-label mt-1" }, [
                _vm._v("Start:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.startDate,
                    expression: "startDate"
                  }
                ],
                staticClass: "text-input date-picker z-2 dropdown",
                attrs: { type: "date", required: "" },
                domProps: { value: _vm.startDate },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.startDate = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "position-relative z-2 flex-grow-1" }, [
              _c("label", { staticClass: "green-label mt-1" }, [
                _vm._v("End:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.endDate,
                    expression: "endDate"
                  }
                ],
                staticClass: "text-input date-picker z-2 dropdown",
                attrs: { type: "date", required: "" },
                domProps: { value: _vm.endDate },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.endDate = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "position-relative z-2" },
              [
                _c("label", { staticClass: "green-label mt-1" }, [
                  _vm._v("Timezone:")
                ]),
                _c(
                  "b-input-group",
                  [
                    _c("b-select", {
                      staticClass: "text-input",
                      attrs: { options: _vm.timezoneOptions, required: "" },
                      model: {
                        value: _vm.currentTimezone,
                        callback: function($$v) {
                          _vm.currentTimezone = $$v
                        },
                        expression: "currentTimezone"
                      }
                    }),
                    _c("b-input-group-append", {
                      staticClass: "align-items-center"
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "position-relative z-2" },
              [
                _c(
                  "b-input-group",
                  [
                    _c("label", { staticClass: "green-label mt-1" }, [
                      _vm._v("Statistic:")
                    ]),
                    _c("b-form-checkbox-group", {
                      attrs: { options: _vm.statisticOptions },
                      model: {
                        value: _vm.selectedStatistics,
                        callback: function($$v) {
                          _vm.selectedStatistics = $$v
                        },
                        expression: "selectedStatistics"
                      }
                    }),
                    _c("b-input-group-append", {
                      staticClass: "align-items-center"
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "position-relative z-2" },
              [
                _c("b-button", { attrs: { type: "submit" } }, [
                  _vm._v("Submit")
                ])
              ],
              1
            )
          ]
        )
      ]),
      _c("div", { staticClass: "green-divider" }),
      _vm.statsCoverageDates
        ? _c("div", [
            _c("div", { staticClass: "calendar-header" }),
            _c(
              "div",
              { staticClass: "calendar-container" },
              _vm._l(_vm.days, function(weekday, weekdayIdx) {
                return _c(
                  "div",
                  { key: weekday, staticClass: "week-column" },
                  [
                    _c("div", { staticClass: "week-column-header" }, [
                      _vm._v(_vm._s(weekday))
                    ]),
                    _vm._l(_vm.statsCoverageDates[weekdayIdx], function(
                      date,
                      dateIdx
                    ) {
                      return _c(
                        "div",
                        {
                          key: weekday + "-" + dateIdx,
                          staticClass: "calendar-date-container",
                          style: "height: " + _vm.dateheight + "px"
                        },
                        [
                          _c("div", { staticClass: "calendar-date-header" }, [
                            _vm._v(" " + _vm._s(date) + " ")
                          ]),
                          _vm._l(_vm.statsCoverageData[date], function(
                            stat,
                            statIdx
                          ) {
                            return _c(
                              "div",
                              {
                                key: weekday + "-" + stat + statIdx,
                                staticClass: "date-stat"
                              },
                              [
                                _c("div", [_vm._v(_vm._s(stat["text"]))]),
                                _c(
                                  "b-progress",
                                  {
                                    class: {
                                      "progress-danger":
                                        _vm.getBarVariant(
                                          stat["value"],
                                          _vm.statMaxCount
                                        ) === "danger",
                                      "progress-warning":
                                        _vm.getBarVariant(
                                          stat["value"],
                                          _vm.statMaxCount
                                        ) === "warning",
                                      "progress-success":
                                        _vm.getBarVariant(
                                          stat["value"],
                                          _vm.statMaxCount
                                        ) === "success"
                                    }
                                  },
                                  [
                                    stat["value"] > 0
                                      ? _c(
                                          "b-progress-bar",
                                          {
                                            attrs: {
                                              value: stat["value"],
                                              max: _vm.statMaxCount,
                                              variant: _vm.getBarVariant(
                                                stat["value"],
                                                _vm.statMaxCount
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(stat["value"]) +
                                                " / " +
                                                _vm._s(_vm.statMaxCount) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "progress-no-devices"
                                          },
                                          [_vm._v("None")]
                                        )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }