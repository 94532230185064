<template>
<div class="">
  <form v-on:submit.prevent="createTask()" class="control-form" v-if="!jobState">
    <div class="flex-row">
      <div class="position-relative z-2 flex-grow-1">
        <label class="green-label mt-1">Target Start:</label>
        <input v-model="startDate" type="date" class="text-input date-picker z-2 dropdown" required>
      </div>
      <div class="position-relative z-2 flex-grow-1">
        <label class="green-label mt-1">Target End:</label>
        <input v-model="endDate" type="date" class="text-input date-picker z-2 dropdown" required>
      </div>
    </div>

    <div class="position-relative z-2">
      <label class="green-label mt-1">Timezone:</label>
      <b-input-group>
        <b-select class="text-input"
                  :options="timezoneOptions" v-model="currentTimezone" required/>
        <b-input-group-append class="align-items-center">
          <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="position-relative z-2">
      <label class="green-label mt-1">Operations:</label>
      <b-checkbox v-model="deleteStats">Delete Existing Stats</b-checkbox>
      <b-checkbox v-model="createStats">Generate Stats</b-checkbox>
    </div>

    <div class="position-relative z-2">
      <label class="green-label mt-1">Related Types:</label>
      <b-input-group>
        <b-select class="text-input" @change="selectAll(false)"
                  :options="relatedTypeOptions" v-model="selectedRelatedType"/>
        <b-input-group-append class="align-items-center">
          <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="position-relative z-2" v-if="selectedRelatedType==='device'">
      <label class="green-label mt-1">Related Devices:</label>
      <b-input-group>
        <b-select class="text-input"
                  :options="deviceSelectionOptions" v-model="deviceSelectionMode"/>
        <b-input-group-append class="align-items-center">
          <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
        </b-input-group-append>
      </b-input-group>
      <div v-if="deviceSelectionMode==='manual'">
        <label>Device Ids:</label>
        <b-input class="w-100" placeholder="Enter Device Ids..." v-model="deviceSelectionManual"></b-input>
      </div>
      <div v-if="deviceSelectionMode==='owner'">
        <label>Owners:</label>
        <ul>
          <li v-for="(user, idx) of deviceSelectOwners" v-bind:key="idx">
            {{user.email}}
            <i class="row-icon-button-danger" :class="$config.icons.general.remove" @click="removeDeviceUser(user)"></i>
          </li>
        </ul>
        <b-button @click="$bvModal.show('select-user')" >Add Owners</b-button>
      </div>
      <div v-if="deviceSelectionMode==='devices'">
        <label>Devices:</label>
        <ul>
          <li v-for="(device, idx) of deviceSelectDevices" v-bind:key="idx">
            #{{device.device_id}} - {{device.device_name}}
            <i class="row-icon-button-danger" :class="$config.icons.general.remove" @click="removeDeviceUser(device)"></i>
          </li>
        </ul>
        <b-button @click="$bvModal.show('select-devices')" >Add Devices</b-button>
      </div>
    </div>

    <div class="position-relative z-2">
      <label class="green-label mt-1">Statistics:</label>
      <b-input-group class="stats-selection">
        <b-form-checkbox v-model="selectAllValue" @change="selectAll" :indeterminate="statsIndeterminate">Select All</b-form-checkbox>
        <b-form-checkbox-group
          v-model="selectedStatistics"
          :options="statisticOptions"
          stacked
        ></b-form-checkbox-group>
        <b-input-group-append class="align-items-center">
          <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
        </b-input-group-append>
      </b-input-group>
    </div>
    <div>
      <label>Summary:</label>
      <ul>
        <li v-if="deleteStats">Statistics within this date range will be deleted.</li>
        <li v-if="createStats">Statistics within this date range will be Run.</li>
        <li>{{selectedStatistics.length}} Statistics Selected. </li>
        <li v-if="selectedRelatedType">Only Stats with Related Type "{{selectedRelatedType}}".</li>
        <li v-if="selectedRelatedIds"> Related Ids in {{selectedRelatedIds}} .</li>
      </ul>
    </div>
    <div class="position-relative z-2">
      <b-button type="submit">Engage!</b-button>
      <b-button @click="clickCanel">Cancel</b-button>
    </div>
  </form>
  <div v-if="jobState">
    <div>
      Job Status: {{jobState}}
    </div>
    <div>
      <label>History</label>
      <ul>
        <li v-for="(event, idx) of jobEvents" v-bind:key="idx">
          {{dt.toLocalDateTime(event.time)}} - {{event.text}}
          <ul v-if="event.object && Object.keys(event.object).length">
            <li v-for="(prop, idx) of Object.keys(event.object)" v-bind:key="idx">
              <span v-if="typeof event.object[prop] === 'string' || typeof event.object[prop] === 'number'">
                {{prop}}: {{event.object[prop]}}
              </span>
              <div v-else>
                {{prop}}:
                <ul >
                  <li v-for="(childProp, idx) of Object.keys(event.object[prop])" v-bind:key="idx">
                  <span>
                    {{childProp}}: {{event.object[prop][childProp]}}
                  </span>
                  </li>
                </ul>
              </div>

            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-if="taskId">
      <TaskProgress :task_id="taskId" @finish="taskComplete" title="Admin Stats Task"></TaskProgress>
    </div>
    <b-button @click="resetJob">Restart</b-button>
  </div>

  <b-modal id="select-user" size="lg">
    <admin-user-list
      is-modal
      modal="select-user"
      multi-select-mode
      @change="onSelectuser"
    ></admin-user-list>
  </b-modal>

  <b-modal id="select-devices" size="lg">
    <admin-device-list
      is-modal
      modal="select-devices"
      multi-select-mode
      @change="onSelectDevice"
    ></admin-device-list>
  </b-modal>
</div>
</template>

<script>
import moment from 'moment/moment'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import TaskProgress from '@/components/stats/TaskProgress.vue'
import AdminUserList from '@/components/admin/AdminUserList.vue'
import AdminDeviceList from '@/components/admin/AdminDeviceList.vue'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'

export default {
  name: 'AdminStatsCreateTask',
  components: { AdminDeviceList, AdminUserList, TaskProgress },
  props: {
    modal_id: String
  },
  data () {
    return {
      dt: dt,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezoneOptions: [],
      selectedStatistics: [],
      relatedTypeOptions: [
        {
          value: null,
          text: '<Select Related Type>'
        },
        {
          value: 'none',
          text: 'None'
        },
        {
          value: 'device',
          text: 'Device'
        },
        {
          value: 'trip',
          text: 'Trip'
        },
        {
          value: 'user',
          text: 'User'
        }
      ],
      deviceSelectionOptions: [
        {
          value: null,
          text: 'All'
        },
        {
          value: 'manual',
          text: 'Manual'
        },
        {
          value: 'owner',
          text: 'Owned By'
        },
        {
          value: 'devices',
          text: 'Select Devices'
        },
      ],
      deviceSelectionManual: '',
      deviceSelectionMode: null,
      deviceSelectOwners: [],
      deviceSelectDevices: [],
      selectedRelatedType: null,
      selectAllValue: false,
      taskId: null,
      deleteStats: true,
      createStats: true,
      jobState: null,
      jobEvents: [],
      statsConfigurationData: null,
    }
  },
  async created () {
    // Populate the TZ list from available zones in the local system
    this.timezoneOptions = Intl.supportedValuesOf('timeZone')
    // This *should* be the user's current timezone.
    this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.statsConfigurationData = await this.getStatOptions()
  },
  methods: {
    resetJob() {
      this.jobEvents = []
      this.jobState = null
      this.taskId = null
    },
    addJobEvent(text, object) {
      this.jobEvents.push({
        time: new Date(),
        text: text,
        object: object
      })
    },
    async createTask () {
      this.jobState = 'Starting'
      this.addJobEvent('Starting Work.')
      this.jobEvents = []
      if (this.deleteStats) {
        this.jobState = 'Delete Stats'
        let deleteSucess = await this.doDeleteStats()
        if (!deleteSucess) {
          return
        }
      }
      if (this.createStats) {
        this.jobState = 'Create Stats'
        this.addJobEvent(`Run Stats: [${this.selectedStatistics}]
      From ${this.startDate} to ${this.endDate} in ${this.timezone}.`)
        let result = await DataProvider.adminRunStats(this.selectedStatistics, this.startDate, this.endDate,
          this.timezone, this.selectedRelatedType, this.selectedRelatedIds)
        if (result.success) {
          this.addJobEvent(`Task Creation Successful. `)
          AlertHelper.successToast('Stats Task Created Successfully!', 'Task Created.')
          console.log(result.data)
          this.taskId = result.data.task.id
          this.jobState = 'Waiting for Task'
        } else {
          ErrorHelper.displayDataErrorToast(result)
          this.addJobEvent(`Task Creation FAILED.`)
          this.jobState = 'DONE! (With Errors)'
        }
      } else {
        this.jobState = 'DONE!'
      }
    },
    async doDeleteStats() {
      this.addJobEvent(`Deleting Existing Stats: [${this.selectedStatistics}]
      From ${this.startDate} to ${this.endDate} in ${this.timezone}.`)
      let result = await DataProvider.adminDeleteStats(this.selectedStatistics, this.startDate, this.endDate,
        this.timezone, this.selectedRelatedType, this.selectedRelatedIds)
      if (result.success) {
        AlertHelper.successToast('Stats Task Created Successfully!', 'Task Created.')
        this.addJobEvent(`Delete Successful. `)
        console.log('Result: ', result.data)
        this.addJobEvent(`Delete Results: `, result.data.deleted_stats)
        return true
      } else {
        ErrorHelper.displayDataErrorToast(result)
        this.addJobEvent(`Delete Unsuccessful!`)
        return false
      }
    },
    taskComplete (status, data) {
      console.log('TASK COMPLETE!', status, data)
      if (status === 'COMPLETE') {
        this.jobState = 'DONE!'
        this.addJobEvent(`Task Completed Successfully. `)
        console.log('Task Result: ', data)
        this.addJobEvent(`Task Results: `, data)
      }
    },
    async getStatOptions () {
      let resp = await DataProvider.adminGetStatTypes()
      if (resp.success) {
        console.log('Stat TypeS: ', resp.data)
        return resp.data.statistic_types
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    clickCanel () {
      this.$bvModal.hide(this.modal_id)
    },
    selectAll (checked) {
      if (checked) {
        console.log(this.statisticOptions)
        this.selectedStatistics = this.statisticOptions.map(x => x.value)
      } else {
        this.selectedStatistics = []
      }
    },
    onSelectuser(users) {
      console.log(users)
      this.deviceSelectOwners = users
    },
    onSelectDevice(devices) {
      console.log('select Devices: ', devices)
      this.deviceSelectDevices = devices
    },
    removeDeviceUser(user) {
      this.deviceSelectOwners = this.deviceSelectOwners.filter(x => x.user_id !== user.user_id)
    },

  },
  computed: {
    selectedRelatedIds () {
      let relatedIds = null
      if (this.selectedRelatedType === 'device' && this.deviceSelectionMode === 'owner') {
        console.log('Selected Owners', this.deviceSelectOwners)
        relatedIds = this.deviceSelectOwners.reduce(
          (devices, user) => devices.concat(user.devices.map(d => d.device_id)), [])
      } else if (this.selectedRelatedType === 'device' && this.deviceSelectionMode === 'manual') {
        relatedIds = this.deviceSelectionManual.split(',').map(i => parseInt(i)).filter(i => !!i)
      } else if (this.selectedRelatedType === 'device' && this.deviceSelectionMode === 'devices') {
        relatedIds = this.deviceSelectDevices.map(d => d.device_id)
      }

      return relatedIds
    },
    statsIndeterminate () {
      return this.selectedStatistics.length > 0 && this.selectedStatistics.length < this.statisticOptions.length
    },
    statisticOptions () {
      console.log('Stats Config: ', this.statsConfigurationData)
      if (!this.statsConfigurationData) {
        return []
      } else {
        return this.statsConfigurationData.filter(x => x.related_type === this.selectedRelatedType).map(stat => {
          return { text: stat.display_name, value: stat.type}
        })
      }
    }
  },
  watch: {
    selectedStatistics (val) {
      this.selectAllValue = (val.length === this.statisticOptions.length)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.stats-selection {
  max-height: 30vh;
  overflow: auto;
  padding: 5px;
  background: $theme-color-background-3;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #ababab;
  border-radius: 5px;
}

</style>
