<template>
  <div class="stats-calendar">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
      <form v-on:submit.prevent="getStatsCoverage()" class="control-form">
        <div class="position-relative z-2 flex-grow-1">
          <label class="green-label mt-1">Start:</label>
            <input v-model="startDate" type="date" class="text-input date-picker z-2 dropdown" required>
        </div>
        <div class="position-relative z-2 flex-grow-1">
          <label class="green-label mt-1">End:</label>
        <input v-model="endDate" type="date" class="text-input date-picker z-2 dropdown" required>
        </div>
        <div class="position-relative z-2">
          <label class="green-label mt-1">Timezone:</label>
          <b-input-group>
            <b-select class="text-input"
                      :options="timezoneOptions" v-model="currentTimezone" required/>
            <b-input-group-append class="align-items-center">
  <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="position-relative z-2">
          <b-input-group>
            <label class="green-label mt-1">Statistic:</label>
            <b-form-checkbox-group
              v-model="selectedStatistics"
              :options="statisticOptions"
            ></b-form-checkbox-group>
            <b-input-group-append class="align-items-center">
              <!--            <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>-->
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="position-relative z-2">
          <b-button type="submit">Submit</b-button>
        </div>
      </form>
    </div>

    <div class="green-divider"></div>
    <div v-if="statsCoverageDates">
      <div class="calendar-header">
<!--        <b-button type="submit"> << </b-button>-->
<!--        <b-button type="submit"> < </b-button>-->
<!--        <b-button type="submit"> > </b-button>-->
<!--        <b-button type="submit"> >> </b-button>-->
      </div>
      <div class="calendar-container">
        <div class="week-column" v-for="(weekday, weekdayIdx) of days" v-bind:key="weekday">
          <div class="week-column-header">{{ weekday }}</div>
          <div class="calendar-date-container" :style="'height: ' + dateheight + 'px'"
               v-for="(date, dateIdx) of statsCoverageDates[weekdayIdx]" v-bind:key="weekday + '-' + dateIdx">
            <div class="calendar-date-header ">
              {{date}}
            </div>
            <div class="date-stat"
                 v-for="(stat, statIdx) of statsCoverageData[date]" v-bind:key="weekday + '-' + stat + statIdx">
              <div>{{stat['text']}}</div>
              <b-progress :class="{
                'progress-danger': getBarVariant(stat['value'], statMaxCount) === 'danger',
                'progress-warning': getBarVariant(stat['value'], statMaxCount) === 'warning',
                'progress-success': getBarVariant(stat['value'], statMaxCount) === 'success'
              }"
              >
                <b-progress-bar v-if="stat['value'] > 0"
                  :value="stat['value']" :max="statMaxCount" :variant="getBarVariant(stat['value'], statMaxCount)">
                  {{stat['value']}} / {{statMaxCount}}
                </b-progress-bar>
                <span class="progress-no-devices" v-else>None</span>
              </b-progress>
            </div>

          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'

import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'
import * as AlertHelper from '../helpers/AlertHelper'
import SearchFilters from '@/components/shared/SearchFilters.vue'
import {adminGetStatTypes} from '../helpers/DataProvider'
import AdminStatsCreateTask from '@/components/admin/AdminStatsCreateTask.vue'
export default {
  name: 'admin-stats-calendar',
  components: {
    AdminStatsCreateTask,
    LoadingBox
  },
  props: {
    products: Array
  },
  data: function () {
    return {
      loading: true,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      timezoneOptions: [],
      statisticOptions: [],
      currentTimezone: null,
      selectedStatistics: [],
      queriesStats: [],
      statsData: null,
      adminUsageData: null,
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Satday'
      ],
      dateheight: 0
    }
  },
  async created () {
    // Populate the TZ list from available zones in the local system
    this.timezoneOptions = Intl.supportedValuesOf('timeZone')
    // This *should* be the user's current timezone.
    this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.statisticOptions = await this.getStatOptions()
    this.adminUsageData = await this.getAdminStats()
    this.loading = false
  },
  methods: {
    async getAdminStats () {
      let resp = await DataProvider.adminUsageStatistics()
      if (resp.success) {
        console.log(resp.data)
        return resp.data.statistics
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getStatOptions () {
      let resp = await DataProvider.adminGetStatTypes()
      if (resp.success) {
        console.log(resp.data)
        return resp.data.statistic_types.map(stat => { return { text: stat.display_name, value: stat.type } })
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getStatsCoverage () {
      if (this.selectedStatistics.length === 0) {
        ErrorHelper.displayGeneralErrorToast('Pick one or more statistics to view.')
        return
      }
      let resp = await DataProvider.adminGetStatCoverage(this.selectedStatistics, this.startDate, this.endDate, this.currentTimezone)
      if (resp.success) {
        console.log(resp.data)
        this.queriesStats = [...this.selectedStatistics] // Only update this when we refresh the data
        this.dateheight = this.selectedStatistics.length * 50
        this.statsData = resp.data.statistics_coverage
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    refresh: async function () {
      this.getStatsCoverage()
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm')
    },
    getBarVariant (val, max) {
      let percent = val / max
      if (percent < 0.75) {
        return 'danger'
      } else if (percent < 0.9) {
        return 'warning'
      } else {
        return 'success'
      }
    }
  },
  computed: {
    statsCoverageDates () {
      if (this.statsData) {
        let data = {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: []
        }
        let startDate = Object.keys(this.statsData)[0]
        let endDate = Object.keys(this.statsData).slice(-1)[0]
        // Pad the columns if we don't start on a sunday
        if (moment(startDate).weekday() !== 0) {
          for (let i = 0; i < moment(startDate).weekday(); i++) {
            data[i].push('')
          }
        }

        Object.keys(this.statsData).forEach(date => data[moment(date).weekday()].push(date))
        console.log('Coverage!', data)
        return data
      } else {
        return null
      }
    },
    statsCoverageData () {
      let data = {}
      if (this.statsData) {
        Object.keys(this.statsData).forEach(date => {
          data[date] = this.queriesStats.map(statType => {
            if (Object.hasOwn(this.statsData[date], statType)) {
              return {
                text: statType,
                value: this.statsData[date][statType].length
              }
            } else {
              return {
                text: statType,
                value: 0
              }
            }
          })
        })
      }
      return data
    },
    statMaxCount () {
      // TODO - This will probably break if the array ordering changes (the stats aren't really indexed)
      let deviceStats = this.adminUsageData.find(x => x.title === 'Device Summary')
      let reportingDevices = deviceStats.items.find(x => x.name === 'Stats Reports Devices')
      console.log('Max Count: ', reportingDevices.value)
      return reportingDevices.value
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-stats-calendar{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: column;
    align-content: stretch;
  }
  .control-form {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
  }

  .control-form * {
    margin: 2px 5px;
  }

  .calendar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .week-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    border: 1px solid black;
  }

  .week-column-header {
    background: $theme-color-background-4;
    color: white;
    font-weight: 600;
  }

  .calendar-date-container {
    margin: 5px;
    width: auto;
    min-height: 150px;
    background: $theme-color-background-2;
    border-radius: 2px;
    padding: 2px;
  }

  .calendar-date-header {
    background: $theme-color-background-4;
    color: $theme-color-primary-3;
    font-weight: 600;
  }

  .date-stat {
    margin-bottom: 3px;
  }

  .progress-danger {
    background: #831414;
    position: relative;
  }

  .progress-warning {
    background: #b48a00;
  }

  .progress-success{
    background: #0f6918;
  }

  .progress-no-devices {
    color: white;
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

</style>
