var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      !_vm.jobState
        ? _c(
            "form",
            {
              staticClass: "control-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createTask()
                }
              }
            },
            [
              _c("div", { staticClass: "flex-row" }, [
                _c(
                  "div",
                  { staticClass: "position-relative z-2 flex-grow-1" },
                  [
                    _c("label", { staticClass: "green-label mt-1" }, [
                      _vm._v("Target Start:")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.startDate,
                          expression: "startDate"
                        }
                      ],
                      staticClass: "text-input date-picker z-2 dropdown",
                      attrs: { type: "date", required: "" },
                      domProps: { value: _vm.startDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.startDate = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "position-relative z-2 flex-grow-1" },
                  [
                    _c("label", { staticClass: "green-label mt-1" }, [
                      _vm._v("Target End:")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endDate,
                          expression: "endDate"
                        }
                      ],
                      staticClass: "text-input date-picker z-2 dropdown",
                      attrs: { type: "date", required: "" },
                      domProps: { value: _vm.endDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endDate = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "position-relative z-2" },
                [
                  _c("label", { staticClass: "green-label mt-1" }, [
                    _vm._v("Timezone:")
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-select", {
                        staticClass: "text-input",
                        attrs: { options: _vm.timezoneOptions, required: "" },
                        model: {
                          value: _vm.currentTimezone,
                          callback: function($$v) {
                            _vm.currentTimezone = $$v
                          },
                          expression: "currentTimezone"
                        }
                      }),
                      _c("b-input-group-append", {
                        staticClass: "align-items-center"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "position-relative z-2" },
                [
                  _c("label", { staticClass: "green-label mt-1" }, [
                    _vm._v("Operations:")
                  ]),
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.deleteStats,
                        callback: function($$v) {
                          _vm.deleteStats = $$v
                        },
                        expression: "deleteStats"
                      }
                    },
                    [_vm._v("Delete Existing Stats")]
                  ),
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.createStats,
                        callback: function($$v) {
                          _vm.createStats = $$v
                        },
                        expression: "createStats"
                      }
                    },
                    [_vm._v("Generate Stats")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "position-relative z-2" },
                [
                  _c("label", { staticClass: "green-label mt-1" }, [
                    _vm._v("Related Types:")
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-select", {
                        staticClass: "text-input",
                        attrs: { options: _vm.relatedTypeOptions },
                        on: {
                          change: function($event) {
                            return _vm.selectAll(false)
                          }
                        },
                        model: {
                          value: _vm.selectedRelatedType,
                          callback: function($$v) {
                            _vm.selectedRelatedType = $$v
                          },
                          expression: "selectedRelatedType"
                        }
                      }),
                      _c("b-input-group-append", {
                        staticClass: "align-items-center"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.selectedRelatedType === "device"
                ? _c(
                    "div",
                    { staticClass: "position-relative z-2" },
                    [
                      _c("label", { staticClass: "green-label mt-1" }, [
                        _vm._v("Related Devices:")
                      ]),
                      _c(
                        "b-input-group",
                        [
                          _c("b-select", {
                            staticClass: "text-input",
                            attrs: { options: _vm.deviceSelectionOptions },
                            model: {
                              value: _vm.deviceSelectionMode,
                              callback: function($$v) {
                                _vm.deviceSelectionMode = $$v
                              },
                              expression: "deviceSelectionMode"
                            }
                          }),
                          _c("b-input-group-append", {
                            staticClass: "align-items-center"
                          })
                        ],
                        1
                      ),
                      _vm.deviceSelectionMode === "manual"
                        ? _c(
                            "div",
                            [
                              _c("label", [_vm._v("Device Ids:")]),
                              _c("b-input", {
                                staticClass: "w-100",
                                attrs: { placeholder: "Enter Device Ids..." },
                                model: {
                                  value: _vm.deviceSelectionManual,
                                  callback: function($$v) {
                                    _vm.deviceSelectionManual = $$v
                                  },
                                  expression: "deviceSelectionManual"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deviceSelectionMode === "owner"
                        ? _c(
                            "div",
                            [
                              _c("label", [_vm._v("Owners:")]),
                              _c(
                                "ul",
                                _vm._l(_vm.deviceSelectOwners, function(
                                  user,
                                  idx
                                ) {
                                  return _c("li", { key: idx }, [
                                    _vm._v(" " + _vm._s(user.email) + " "),
                                    _c("i", {
                                      staticClass: "row-icon-button-danger",
                                      class: _vm.$config.icons.general.remove,
                                      on: {
                                        click: function($event) {
                                          return _vm.removeDeviceUser(user)
                                        }
                                      }
                                    })
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$bvModal.show("select-user")
                                    }
                                  }
                                },
                                [_vm._v("Add Owners")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deviceSelectionMode === "devices"
                        ? _c(
                            "div",
                            [
                              _c("label", [_vm._v("Devices:")]),
                              _c(
                                "ul",
                                _vm._l(_vm.deviceSelectDevices, function(
                                  device,
                                  idx
                                ) {
                                  return _c("li", { key: idx }, [
                                    _vm._v(
                                      " #" +
                                        _vm._s(device.device_id) +
                                        " - " +
                                        _vm._s(device.device_name) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "row-icon-button-danger",
                                      class: _vm.$config.icons.general.remove,
                                      on: {
                                        click: function($event) {
                                          return _vm.removeDeviceUser(device)
                                        }
                                      }
                                    })
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$bvModal.show("select-devices")
                                    }
                                  }
                                },
                                [_vm._v("Add Devices")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "position-relative z-2" },
                [
                  _c("label", { staticClass: "green-label mt-1" }, [
                    _vm._v("Statistics:")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "stats-selection" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { indeterminate: _vm.statsIndeterminate },
                          on: { change: _vm.selectAll },
                          model: {
                            value: _vm.selectAllValue,
                            callback: function($$v) {
                              _vm.selectAllValue = $$v
                            },
                            expression: "selectAllValue"
                          }
                        },
                        [_vm._v("Select All")]
                      ),
                      _c("b-form-checkbox-group", {
                        attrs: { options: _vm.statisticOptions, stacked: "" },
                        model: {
                          value: _vm.selectedStatistics,
                          callback: function($$v) {
                            _vm.selectedStatistics = $$v
                          },
                          expression: "selectedStatistics"
                        }
                      }),
                      _c("b-input-group-append", {
                        staticClass: "align-items-center"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _c("label", [_vm._v("Summary:")]),
                _c("ul", [
                  _vm.deleteStats
                    ? _c("li", [
                        _vm._v(
                          "Statistics within this date range will be deleted."
                        )
                      ])
                    : _vm._e(),
                  _vm.createStats
                    ? _c("li", [
                        _vm._v("Statistics within this date range will be Run.")
                      ])
                    : _vm._e(),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.selectedStatistics.length) +
                        " Statistics Selected. "
                    )
                  ]),
                  _vm.selectedRelatedType
                    ? _c("li", [
                        _vm._v(
                          'Only Stats with Related Type "' +
                            _vm._s(_vm.selectedRelatedType) +
                            '".'
                        )
                      ])
                    : _vm._e(),
                  _vm.selectedRelatedIds
                    ? _c("li", [
                        _vm._v(
                          " Related Ids in " +
                            _vm._s(_vm.selectedRelatedIds) +
                            " ."
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                { staticClass: "position-relative z-2" },
                [
                  _c("b-button", { attrs: { type: "submit" } }, [
                    _vm._v("Engage!")
                  ]),
                  _c("b-button", { on: { click: _vm.clickCanel } }, [
                    _vm._v("Cancel")
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.jobState
        ? _c(
            "div",
            [
              _c("div", [_vm._v(" Job Status: " + _vm._s(_vm.jobState) + " ")]),
              _c("div", [
                _c("label", [_vm._v("History")]),
                _c(
                  "ul",
                  _vm._l(_vm.jobEvents, function(event, idx) {
                    return _c("li", { key: idx }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dt.toLocalDateTime(event.time)) +
                          " - " +
                          _vm._s(event.text) +
                          " "
                      ),
                      event.object && Object.keys(event.object).length
                        ? _c(
                            "ul",
                            _vm._l(Object.keys(event.object), function(
                              prop,
                              idx
                            ) {
                              return _c("li", { key: idx }, [
                                typeof event.object[prop] === "string" ||
                                typeof event.object[prop] === "number"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(prop) +
                                          ": " +
                                          _vm._s(event.object[prop]) +
                                          " "
                                      )
                                    ])
                                  : _c("div", [
                                      _vm._v(" " + _vm._s(prop) + ": "),
                                      _c(
                                        "ul",
                                        _vm._l(
                                          Object.keys(event.object[prop]),
                                          function(childProp, idx) {
                                            return _c("li", { key: idx }, [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(childProp) +
                                                    ": " +
                                                    _vm._s(
                                                      event.object[prop][
                                                        childProp
                                                      ]
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ])
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ]),
              _vm.taskId
                ? _c(
                    "div",
                    [
                      _c("TaskProgress", {
                        attrs: {
                          task_id: _vm.taskId,
                          title: "Admin Stats Task"
                        },
                        on: { finish: _vm.taskComplete }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("b-button", { on: { click: _vm.resetJob } }, [
                _vm._v("Restart")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        { attrs: { id: "select-user", size: "lg" } },
        [
          _c("admin-user-list", {
            attrs: {
              "is-modal": "",
              modal: "select-user",
              "multi-select-mode": ""
            },
            on: { change: _vm.onSelectuser }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        { attrs: { id: "select-devices", size: "lg" } },
        [
          _c("admin-device-list", {
            attrs: {
              "is-modal": "",
              modal: "select-devices",
              "multi-select-mode": ""
            },
            on: { change: _vm.onSelectDevice }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }